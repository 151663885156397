@charset "UTF-8";

@import "../_setting";
@import "../_mixin";

.l-main {
  margin-top: 0;
}

.topVisual {
  position: relative;
  z-index: z(main2);
  padding-bottom: 180px;
  background: {
    image: url(../../img/top/bg_top.png);
    repeat: no-repeat;
    position: center top;
  };
  @include bpTb {
    padding-bottom: 30px;
    background: {
      image: url(../../img/top/bg_top_sp.jpg);
      size: cover;
    };
  }
  &-wrap {
    position: relative;
    max-width: 1050px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 70px;
    @include bpTb {
      max-width: 100%;
      padding-top: 250px;
      margin-right: 0;
      margin-left: 0;
    }
    @include bpSp {
      padding-top: 200px;
    }
    @include bpSps {
      padding-top: 170px;
    }
  }
  &-slide {
    li {
      height: 480px; // 440px + 40px
      position: relative;
      margin-right: 25px;
      @include bpPcs {
        margin-right: 0;
      }
      @include bpTb {
        height: 75vw;
      }
      img {
        margin-left: auto;
        @include bpTb {
          width: 100%;
          margin-right: auto;
        }
      }
    }
    &-text {
      position: absolute;
      bottom: 0;
      right: -25px;
      text-align: right;
      @include bpPcs {
        right: 0;
      }
      .textarea {
        position: relative;
        display: inline-block;
        margin-bottom: 8px;
        padding: 10px 20px 10px 15px;
        background-color: $textColor;
        color: #fff;
        @include f-size(21);
        font-weight: 500;
        @include bpPcs {
          @include f-size(18);
        }
        @include bpTb {
          margin-bottom: 4px;
          padding: 6px 15px 6px 10px;
          @include f-size(17);
        }
        @include bpSps {
          @include f-size(15);
        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -25px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 57px 25px 0 0;
          border-color: transparent $textColor transparent transparent;
          @include bpPcs {
            border-top-width: 52px;
          }
          @include bpTb {
            border-top-width: 42px;
          }
          @include bpSps {
            border-top-width: 39px;
          }
        }
      }
    }

    .slick-slide {
      img {
        opacity: 0;
      }
      &.scroll {
        img {
          animation: faderight 4.5s;
        }
      }
    }
    @keyframes faderight {
      0% {
          opacity: 0;
          transform: translateX(-100px);
        }
      10% {
          opacity: 1;
          transform: translateX(0);
      }
      85% {
          opacity: 1;
          transform: translateX(0);
      }
      100% {
        opacity: 0;
        transform: translateX(0);
      }
    }

    .topVisual-slide-text {
      width: 100%;
      overflow: hidden;
      .textarea {
        opacity: 0;
      }
      &.scroll {
        .textarea {
          animation: fadeleft 4.5s;
        }
      }
    }
    @keyframes fadeleft {
      0% {
          opacity: 0;
          transform: translateX(100px);
        }
      10% {
          opacity: 1;
          transform: translateX(0);
      }
      85% {
          opacity: 1;
          transform: translateX(0);
      }
      100% {
        opacity: 0;
        transform: translateX(0);
      }
    }

    // slick arrow
    .prev {
      &::after {
        content: none;
      }
    }
    .next {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -25px;
        width: 15px;
        height: 15px;
        border-top: solid 3px $color1;
        border-right: solid 3px $color1;
        transform: rotate(45deg) translateY(-50%);
        cursor: pointer;
        @include breakpoint(1150px) {
          right: 12px;
        }
        @include bpPcs {
          content: none;
        }
      }
    }

    // slick dots
    .slick-dots {
      right: 20px;
      width: auto;
      @include bpTb {
        right: auto;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
      }
      @include bpSps {
        bottom: -30px;
      }
      li {
        margin-right: auto;
        margin-left: auto;
        height: auto;
        width: 9px;
        height: 9px;
        margin-right: 4px;
        margin-left: 4px;
        button {
          width: 9px;
          height: 9px;
          &::before {
            content: '';
            opacity: 1;
            width: 9px;
            height: 9px;
            border: 1px solid $color1;
            border-radius: 20px;
            background-color: #fff;
          }
        }
        &.slick-active {
          button {
            width: 9px;
            height: 9px;
            &::before {
              content: '';
              opacity: 1;
              width: 9px;
              height: 9px;
              border: 1px solid $color1;
              border-radius: 20px;
              background-color: $color1;
            }
          }
        }
      }
    }
  }
  &-catch {
    position: absolute;
    bottom: -10px;
    left: 25px;
    @include bpPcs {
      left: 20px;
    }
    @include bpTb {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      top: 70px;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
    }
    .en {
      display: block;
      color: $color1;
      font-family: $Rubik;
      @include f-size(80);
      font-weight: 700;
      font-style: italic;
      letter-spacing: 0.04em;
      line-height: 1.1em;
      @include bpPcs {
        @include f-size(70);
      }
      @include bpSp {
        @include f-size(50);
      }
      @include bpSps {
        @include f-size(38);
      }
      .large {
        @include bpTb {
          display: block;
          padding-top: 10px;
          @include f-size(115);
        }
        @include bpSp {
          @include f-size(90);
        }
        @include bpSps {
          @include f-size(65);
        }
      }
    }
    .ja {
      display: block;
      @include f-size(27);
      font-weight: 700;
      letter-spacing: 0.06em;
      @include bpPcs {
        @include f-size(25);
      }
      @include bpTb {
        order: -1;
        padding-bottom: 5px;
      }
      @include bpSp {
        @include f-size(19);
      }
      @include bpSps {
        @include f-size(14);
      }
    }
  }
  // 動き
  .fadeTopSlide {
    animation: fadeTopSlide 1s;
  }
  @keyframes fadeTopSlide {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.news {
  position: relative;
  background-color: #f7f7f7;
  margin-top: -150px;
  padding-top: 70px;
  padding-bottom: 20px;
  @include bpTb {
    margin-top: 0;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .m-title3 {
    position: relative;
    margin-bottom: 30px;
  }
  .btnWrap {
    position: relative;
    margin-top: 35px;
    @include bpTb {
      margin-top: 0;
      padding-top: 45px;
      padding-bottom: 45px;
      border-bottom: 1px solid #acacac;
      text-align: center;
    }
    &::before {
      content: '';
      top: 50%;
      right: 0;
      position: absolute;
      width: calc(100% - 250px);
      height: 1px;
      background-color: #acacac;
      @include bpTb {
        content: none;
      }
    }
  }
}

.event {
  position: relative;
  background-color: #f7f7f7;
  @include bpTb {
    margin-top: 0;
  }
  .m-title3 {
    margin-bottom: 30px;
  }
  .btnWrap {
    position: relative;
    margin-top: 35px;
    text-align: right;
    @include bpTb {
      margin-top: 0;
      padding-top: 45px;
      padding-bottom: 60px;
      text-align: center;
    }
  }
}

.concept {
  height: 783px;
  background: {
    image: url(../../img/top/bg_concept.jpg);
    repeat: no-repeat;
    position: center top;
  };
  @include bpTb {
    height: auto;
    margin-bottom: 210px;
    padding-top: 66%;
    background: {
      image: url(../../img/top/bg_concept_sp.jpg);
      repeat: no-repeat;
      position: center top;
      size: cover;
    };
    @include bpSps {
      margin-bottom: 250px;
    }
  }
  .m-wrapper {
    position: relative;
    height: 100%;
  }
  &-block {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    max-width: 520px;
    padding: 30px 50px;
    border: 4px solid $textColor;
    @include bpTb {
      max-width: $baseWidthSp;
      padding: 25px 30px;
      top: 45px;
      left: 50%;
      transform: translate(-50%,-50%);
      border: 2px solid $textColor;
    }
    @include bpSps {
      top: 75px;
    }
    &-title {
      display: block;
      font-family: $Rubik;
      font-style: italic;
      @include f-size(60);
      font-weight: 500;
      letter-spacing: 0.1em;
      text-align: center;
      line-height: 1.5em;
      @include bpPcs {
        @include f-size(50);
      }
      @include bpTb {
        padding-bottom: 10px;
        @include f-size(40);
      }
      @include bpSps {
        @include f-size(30);
      }
    }
    &-text {
      display: block;
      @include f-size(18);
      font-weight: 500;
      line-height: 2em;
      text-align: center;
      @include bpPcs {
        @include f-size(16);
      }
      @include bpTb {
        @include f-size(14);
        letter-spacing: 0.036em;
        text-align: left;
      }
    }
  }
}

.point {
  &-title {
    display: flex;
    align-items: center;
    color: $color1;
    @include bpTb {
      display: block;
    }
    &-wrap {
      text-align: center;
      .en {
        display: block;
        font-family: $Rubik;
        font-style: italic;
        @include f-size(31);
        font-weight: 500;
        line-height: 1;
        @include bpPcs {
          @include f-size(24);
        }
        @include bpTb {
          @include f-size(17);
        }
      }
      .num {
        display: block;
        font-family: $Rubik;
        font-style: italic;
        @include f-size(105);
        letter-spacing: -0.04em;
        text-indent: -0.04em;
        line-height: 1;
        @include bpPcs {
          @include f-size(80);
        }
        @include bpTb {
          @include f-size(55);
        }
      }
    }
    &-text {
      padding-left: 35px;
      @include f-size(28);
      font-weight: 700;
      line-height: 1.5em;
      @include bpPcs {
        padding-top: 5px;
        @include f-size(23);
      }
      @include bpTb {
        padding-left: 0;
      }
      @include bpSp {
        @include f-size(20);
      }
      &.center {
        text-align: center;
        @include bpTb {
          text-align: left;
        }
      }
    }
    &.white {
      color: #fff;
    }
    &.nowrap {
      display: block;
    }
  }
  &-text {
    font-weight: 500;
    line-height: 2em;
    @include bpPcs {
      @include f-size(15);
    }
    @include bpSp {
      @include f-size(14);
    }
    &.center {
      text-align: center;
      @include bpTb {
        text-align: left;
      }
    }
    &.white {
      color: #fff;
    }
  }

  &-one {
    margin-top: -4px;
    height: 1013px;
    background: {
      image: url(../../img/top/bg_point1.jpg);
      repeat: no-repeat;
      position: center top;
    };
    @include bpTb {
      padding-top: 60px;
      padding-bottom: 70px;
      height: auto;
      background: {
        image: url(../../img/top/bg_point1_sp.jpg);
        repeat: no-repeat;
        position: center top;
        size: cover;
      };
    }
    @include bpSps {
      padding-top: 40px;
      padding-bottom: 50px;
    }
    .point-title {
      margin-bottom: 15px;
      @include bpTb {
        padding-top: 10px;
      }
      &-text {
        padding-left: 0;
        @include bpPcs {
          padding-top: 5px;
        }
      }
    }
    &-wrap {
      display: flex;
      justify-content: space-between;
      @include bpTb {
        flex-wrap: wrap;
      }
      li {
        position: relative;
        width: 23.5%;
        margin-top: 35px;
        @include bpTb {
          width: 47.5%;
          margin-top: 40px;
        }
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          height: calc(100% - 100px);
          background-color: #eeeeee;
        }
        .image {
          position: relative;
          z-index: z(main2);
        }
        .text {
          position: absolute;
          z-index: z(main2);
          bottom: -20px;
          right: -10px;
          padding: 10px 20px 10px 15px;
          background-color: $textColor;
          color: #fff;
          font-family: $Rubik;
          font-style: italic;
          @include f-size(22);
          font-weight: 500;
          letter-spacing: 0.1em;
          line-height: 1.2em;
          @include bpPcs {
            @include f-size(18);
          }
          @include bpTb {
            padding: 6px 20px 6px 15px;
            @include f-size(16);
          }
          @include bpSps {
            @include f-size(14);
          }
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -25px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 46px 25px 0 0;
            border-color: transparent $textColor transparent transparent;
            @include bpPcs {
              border-top-width: 41px;
            }
            @include bpTb {
              border-top-width: 31px;
            }
            @include bpSps {
              border-top-width: 28px;
            }
          }
        }
      }
    }
    .btnWrap {
      margin-top: 70px;
      text-align: center;
    }
  }
  &-two {
    background-color: #fff;
    @include bpTb {
      padding-top: 35px;
      padding-bottom: 70px;
    }
    @include bpSps {
      padding-top: 20px;
      padding-bottom: 50px;
    }
    &-wrap {
      display: flex;
      align-items: center;
      @include bpTb {
        flex-wrap: wrap;
      }
      .textarea {
        width: 50%;
        @include bpTb {
          width: 100%;
        }
        .point-title {
          padding-bottom: 30px;
          @include bpTb {
            padding-bottom: 10px;
          }
        }
        .point-text {
          padding-bottom: 40px;
          @include bpTb {
            padding-bottom: 25px;
          }
        }
        .btnWrap {
          text-align: center;
        }
      }
      .imagearea {
        width: 50%;
        margin-top: -70px;
        padding-left: 50px;
        @include bpTb {
          order: -1;
          width: 70%;
          margin-top: 0;
          margin-right: auto;
          margin-left: auto;
          padding-bottom: 25px;
          padding-left: 0;
        }
      }
    }
  }
  &-three {
    height: 524px;
    padding-top: 120px;
    background: {
      image: url(../../img/top/bg_point3.jpg);
      repeat: no-repeat;
      position: center bottom;
    };
    @include bpPcs {
      height: auto;
    }
    @include bpTb {
      padding-top: 25px;
      padding-bottom: 70px;
      background: {
        image: url(../../img/top/bg_point3_sp.jpg);
        size: cover;
      };
    }
    @include bpSps {
      padding-top: 20px;
      padding-bottom: 50px;
    }
    &-wrap {
      display: flex;
      align-items: center;
      @include bpTb {
        flex-wrap: wrap;
      }
      .textarea {
        width: 50%;
        @include bpTb {
          width: 100%;
        }
        .point-title {
          padding-bottom: 30px;
          @include bpTb {
            padding-bottom: 10px;
          }
        }
        .point-text {
          padding-bottom: 40px;
          @include bpTb {
            padding-bottom: 25px;
          }
        }
        .btnWrap {
          text-align: right;
          @include bpTb {
            text-align: center;
          }
        }
      }
      .imagearea {
        order: -1;
        width: 50%;
        margin-top: -70px;
        padding-right: 50px;
        @include bpTb {
          width: 70%;
          margin-top: 0;
          margin-right: auto;
          margin-left: auto;
          padding-right: 0;
          padding-bottom: 25px;
          text-align: center;
        }
      }
    }
  }
  &-four {
    height: 976px;
    margin-top: -3px;
    padding-top: 120px;
    position: relative;
    background: {
      image: url(../../img/top/bg_point4.jpg);
      repeat: no-repeat;
      position: center bottom;
    };
    @include bpTb {
      height: auto;
      margin-top: 0;
      margin-bottom: 270px;
      padding-bottom: 34%;
      background: {
        image: url(../../img/top/bg_point4_sp.jpg);
        repeat: no-repeat;
        position: center top;
        size: cover;
      };
      @include bpSp {
        margin-bottom: 300px;
      }
      @include bpSps {
        margin-bottom: 78%;
      }
      @include breakpoint(375px) {
        margin-bottom: 88%;
      }
      @include breakpoint(350px) {
        margin-bottom: 105%;
      }
    }
    &-wrap {
      display: flex;
      align-items: center;
      @include bpTb {
        display: block;
        position: absolute;
        top: 86.5%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      }
      @include bpSps {
        top: 82.5%;
      }
      .textarea {
        width: calc(100% - 240px);
        @include bpTb {
          width: 100%;
        }
        .point-title {
          padding-bottom: 30px;
          @include bpTb {
            padding-bottom: 10px;
          }
        }
        .point-text {
          padding-bottom: 40px;
          @include bpTb {
            padding-bottom: 25px;
          }
        }
      }
      .btnWrap {
        padding-left: 70px;
        @include bpTb {
          padding-left: 0;
          text-align: center;
        }
      }
    }
  }
  &-five {
    height: 546px;
    background: {
      image: url(../../img/top/bg_point5.jpg);
      repeat: no-repeat;
      position: center top;
    };
    @include bpPcs {
      height: auto;
      padding-bottom: 30px;
    }
    @include bpTb {
      padding-bottom: 60px;
      background: {
        image: url(../../img/top/bg_point1_sp.jpg);
        size: cover;
      };
    }
    @include bpSps {
      padding-bottom: 55px;
    }
    &-wrap {
      display: flex;
      align-items: center;
      @include bpTb {
        flex-wrap: wrap;
      }
      .textarea {
        width: 50%;
        @include bpTb {
          width: 100%;
        }
        .point-title {
          padding-bottom: 30px;
          @include bpTb {
            padding-bottom: 10px;
          }
        }
        .point-text {
          padding-bottom: 40px;
          @include bpTb {
            padding-bottom: 25px;
          }
        }
        .btnWrap {
          text-align: right;
          @include bpTb {
            text-align: center;
          }
        }
      }
      .imagearea {
        position: relative;
        order: -1;
        width: 50%;
        margin-top: -70px;
        @include bpTb {
          width: 100%;
          margin-top: -30px;
          padding-bottom: 20px;
        }
      }
    }
  }
}

.contents {
  background: {
    image: url(../../img/top/bg_other1.jpg);
    repeat: no-repeat;
  };
  @include bpPcs {
    background: {
      size: cover
    };
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 850px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    @include bpPcs {
      display: block;
      text-align: center;
      max-width: $baseWidthSp;
      padding-top: 80px;
      padding-bottom: 80px;
    }
    @include bpTb {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .m-btn2 {
      @include bpPcs {
        width: 80%;
      }
      &-iconText {
        &.calendar {
          &::before {
            content: '\f073';
          }
        }
        &.sitemap {
          &::before {
            content: '\f0e8';
          }
        }
        &.comments {
          &::before {
            content: '\f086';
          }
        }
      }
      &-iconImage {
        &.about {
          &::before {
            background: {
              image: url(../../img/icon_human_white.svg);
            };
            width: 40px;
            height: 51px;
          }
        }
        &:hover {
          &::before {
            background: {
              image: url(../../img/icon_human_black.svg);
            };
          }
        }
      }
      &:nth-child(n+3) {
        margin-top: 25px;
      }
      &:nth-child(n+2) {
        @include bpPcs {
          margin-top: 25px;
        }
        @include bpTb {
          margin-top: 15px;
        }
      }
    }
  }
}

.sns {
  padding-top: 80px;
  padding-bottom: 90px;
  @include bpPcs {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  @include bpSp {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .m-title1 {
    margin-bottom: 30px;
    @include bpTb {
      margin-bottom: 20px;
    }
  }
  &-list {
    display: flex;
    justify-content: space-between;
    @include bpPcs {
      display: block;
      text-align: center;
    }
    .m-btn3 {
      @include bpPcs {
        width: 80%;
      }
      &:nth-child(n+2) {
        @include bpPcs {
          margin-top: 25px;
        }
        @include bpTb {
          margin-top: 15px;
        }
      }
    }
  }
}

.access {
  &-wrap {
    background-color: $color1;
    padding-top: 85px;
    @include bpPcs {
      padding-top: 65px;
    }
    @include bpTb {
      padding-top: 40px;
    }
  }
  .m-title1 {
    margin-bottom: 20px;
  }
  &-text {
    color: #fff;
    text-align: center;
    @include bpSp {
      @include f-size(13);
      text-align: left;
    }
    small {
      @include f-size(13);
      @include bpPcs {
        @include f-size(12);
      }
    }
  }
  .btnWrep {
    margin-top: 40px;
    padding-bottom: 50px;
    text-align: center;
    @include bpPcs {
      margin-top: 25px;
    }
  }
  &-gmap {
    @include breakpoint($maxWidth) {
      position: relative;
      height: 400px;
      overflow: hidden;
    }
    @include bpPcs {
      height: 0;
      padding-bottom: 56.25%;
    }
    @include bpSp {
      padding-bottom: 85%;
    }
    iframe {
      @include breakpoint($maxWidth) {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}
